<ng-template [ngIf]="displayName === 'List'">
    <div>
      <button type="button" class="btn btn-primary" (click)="goToAnotherComponent()"   style="margin-top: 30px; margin-left: 570px; color: #8F1402; background: none; border: 2px solid#8F1402; width: 300px; border-radius: 15px;">+ Add New</button>
    </div>
    
      <div class="card-container" >
  
        <div class="row">
          <body>
            <div class="frame">
              <div
                class="text-wrapper"
                [ngClass]="{ 'selected-tab': activeTab === 'HCP', 'tab': activeTab !== 'HCP' }" 
                (click)="updateTab('HCP')"
              >HCP </div>
              <div
                class="div"
                [ngClass]="{ 'selected-tab': activeTab === 'Ops', 'tab': activeTab !== 'Ops' }"
                (click)="updateTab('Ops')"
              >Ops </div>
            </div>
          </body>
        </div>
           <hr>
           <div *ngIf="displaymessage" class="error-message">
            {{ displaymessage }}
          </div>
        <div class="incontainers">
          
          <div class="scroll-bar">
            
              <div class="scroll-content" >

         <!-- Display error message if no users exist -->
<div *ngIf="!users || users.length === 0" class="no-data-message">
  User doesn't have data.
</div>

<!-- Display user cards if there is data -->
<div class="c" *ngFor="let user of users; let i = index">
  <div class="personcard" (click)="person(user, i)">
    <div class="cardtag" [ngClass]="getStatusFontClass(user.Tag)" [style.background-color]="getStatus(user.Tag)">
      <span style="margin: 5px;">{{ user.status }}</span>
    </div>

    <div class="a">
      <div style="width: 120px; height: 120px; position: relative">
        <div style="width: 10.68px; height: 100.68px; left: 5px; top: 10px; position: absolute">
          <img src="assets/images/Group.png" />
        </div>
      </div>

      <!-- Content when not loading -->
      <div class="container" *ngIf="!(isLoading && selectedIndex === i)">
        <div class="b">
          <div style="color: black; font-size: 24px; font-weight: 600;">
            {{ user.FullName.length <= 18 ? user.FullName : user.FullName.substring(0, 18).trim() + '...' }}
          </div>

          <div style="color: black; font-size: 14px;">{{ user.Gender }}</div>
          <div style="color: black; font-size: 14px; font-weight: 600;">{{ user.Category }}</div>
          <div style="color: black; font-size: 14px;">{{ formatDateString(user.Date_of_Birth) }}</div>
          <div style="color: black; font-size: 14px;">{{ user.MobileNumber }}</div>

          <p style="color: black; font-size: 14px;">
            <ng-container *ngIf="user.Email.length <= 24">
              {{ user.Email }}
            </ng-container>
            <ng-container *ngIf="user.Email.length > 24">
              {{ user.Email.substring(0, 24) }}<br />{{ user.Email.substring(24) }}
            </ng-container>
          </p>
        </div>
      </div>

      <!-- Loading spinner -->
      <div class="loading-animation" *ngIf="isLoading && selectedIndex === i">
        <mat-spinner color="warn"></mat-spinner>
      </div>
    </div>
  </div>
</div>
</div>

      </div>
        </div>
       
      </div>
    </ng-template>



    
  
  
  