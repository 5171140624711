<ng-template [ngIf]="displayName==='License'">
  <div class="main-content">
    <div class="container-fluid">
      <button mat-icon-button mat-dialog-close color="warn" style="margin-left:500px;" (click)="closeLicense()">
        <mat-icon>close</mat-icon>
      </button>
        <div class="popcontainer">
          <form #myForm="ngForm" (ngSubmit)="licensePost(myForm)">
            <div style="display: flex;">
              <h1 class="Details" style="margin-left: 150px;font-weight: 700;">License Details</h1>
              <button type="button" style="margin-left: 180px;" (click)="closeLicense()" class="btn-close" ></button> 
            </div>
            <ng-container *ngIf="errorText !== ''">
              <div style="color: red; margin-top: 10px;">{{ errorText }}</div>
            </ng-container>
            <hr> 
          <div class="form-container">
              <!-- License Authority -->
          <div class="form-group">
            <label for="License Authority" class="sub">License Authority</label>
            <select [(ngModel)]="LicenseAuthority" id="LicenseAuthority" name="LicenseAuthority" class="form-control" style="max-width: 600px;" class="rounded-input" required>
              <option value="DHA">DHA</option>
              <option value="DOH">DOH</option>
              <option value="MOH">MOH</option>
              <option value="Other">Other</option>
            </select>
            <input type="text" [(ngModel)]="LicenseAuthorityother" name='LicenseAuthorityother'  *ngIf="LicenseAuthority === 'Other'" placeholder="Enter other category" class="form-control" style="max-width: 600px; margin-top: 5px;" class="rounded-input" required>
            <ng-container *ngIf="myForm.controls['selectedCategoryothers']?.invalid && myForm.controls['selectedCategoryothers']?.touched">
              <div style="color: red; margin-top: 10px;">Please enter a value for other category.</div>
            </ng-container>              
          </div>
          <!-- License Number -->
          <div class="form-group">
            <label for="License number # "  class="sub">License #</label>
            <input type="text" id="LicenseNumber" name="LicenseNumber" placeholder="Type here" size="60" class="rounded-input" [(ngModel)]="Licensenumber" required >
          </div>
          <div class="form-group">
            <label for="specialisation"  class="sub">Specialization</label>
            <input type="text" id="specialisation" name="LicenseSpecialisation" placeholder="Type here" size="60" class="rounded-input" [(ngModel)]="LicenseSpecialisation" required >
          </div>
          <!-- Validity Information -->
          <div class="form-group">
            <label for="Validity Information"  class="sub">Validity Information</label>
          <div class="row">
            <!-- Issued date -->
            <div class="col">
              <label for="issuedDate" class="col_1" style="font-size: small;">Issue date</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="startPicker" placeholder="DD-MMM-YYYY" name="personDateOfBirth" [(ngModel)]="issueddate" required/>
                <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                <mat-datepicker #startPicker></mat-datepicker>
              </mat-form-field>
            </div>
            <div class="col">
              <label for="validatetill" style="font-size: small;margin-top: 10px;">Valid till</label>
              <mat-form-field appearance="outline" class="w-100">
                <input matInput [matDatepicker]="startPickers" placeholder="DD-MMM-YYYY" name="validatetill" [(ngModel)]="validatetill" [disabled]="lifelongvalidity"/>
                <mat-datepicker-toggle matSuffix [for]="startPickers"></mat-datepicker-toggle>
                <mat-datepicker #startPickers></mat-datepicker>
              </mat-form-field>
            </div> 
            <div class="col">
              <input type="checkbox" id="lifeLongValidity" [(ngModel)]="lifelongvalidity" name="lifelongvalidity"  (change)="onValidityChange()"/>
              <label for="lifeLongValidity" style="color: black; font-size: small; margin-left: 10px;">Life long Validity</label>
            </div>
            </div>
          </div>
          <!-- File Upload -->
          <div class="form-group">
            <label for="file" class="sub">License Proof</label>
            <ng-container *ngIf="errorMessage !== ''">
              <div style="color: red; margin-top: 10px;">{{ errorMessage }}</div>
            </ng-container>
            <div class="custom-file-input"  style="margin-bottom:10px;margin-top:10px" style="display: flex;">
              <input type="file" id="file" name="licenseupload" accept="image/*" value="licenseupload" (change)="onFileSelected($event)" style="display: none;" >
              <label for="file" class="uploadButton"><p></p>Upload</label>
              <div class="image-path">{{licenseupload?.name ||"No File Choosen" }}</div> 
            </div>
          </div>
          <div class="modal-footer">
            <button type="submit" style=" margin-right: 45%; width: 110px; height: 40px; border-radius: 8px; gap: 13px; background-color: #8F1402; margin-top: 20px;color: #FFFFFF;">Save</button>
        </div>
      </div>
    </form>
   </div>
  </div>
 </div>
</ng-template> 

  <ng-template [ngIf]="displayName==='Eductaion'">
    <div class="main-content">
      <div class="container-fluid">
        <button mat-icon-button mat-dialog-close color="warn" style="margin-left:500px;" (click)="closeLicense()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="popcontainer">
          <form #educationForm="ngForm" (ngSubmit)="educationPost(educationForm)">
            <div style="display: flex;">
              <h1 class="Details" style="margin-left: 35%;font-weight: 700;">Education </h1>
              <button type="button" style="margin-left: 180px;" (click)="closeLicense()" class="btn-close" ></button>
            </div>
            <ng-container *ngIf="errorEducation !== ''">
                <div style="color: red; margin-top: 10px;">{{ errorEducation }}</div>
              </ng-container>
            <hr>
            <div class="form-container">
            <div class="modal-body" style="margin-right: 30px; margin-left: 30px;"> 
              <label for="Name of Institute" class="sub">Name of Institute</label><br>
              <input type="text" id="Name of Institute" name="Name of Institute" placeholder="Type here" size="60" style="margin-bottom: 20px;max-width: 600px;" class="rounded-input" [(ngModel)]="nameOfInstitute" required><br>

              <label for="Type of Degree" class="sub">Type of Degree</label><br>
              <input type="text" id="Type of Degree" name="Type of Degree" placeholder="Type here" size="60" style="margin-bottom: 20px;max-width: 600px;" class="rounded-input"[(ngModel)]="typeOfDegree" required><br>

              <label for="field" class="sub">Field of Study</label><br>
              <input type="text" id="field" name="field" placeholder="Type here" size="60" style="margin-bottom: 20px;max-width: 600px;" class="rounded-input" [(ngModel)]="fieldOfStudy" required><br>

              <label for="country" class="sub">Country</label><br>
              <select name="Country" id="Country" class="form-control rounded" [(ngModel)]="Country" (ngModelChange)="onCountryChange($event)" style="width: 100%; background: #f9f9f9;"class="rounded-input" required>
                <option [value]="country" *ngFor="let country of CountryList; let i = index">{{ country }}</option>
              </select>          
              <div class="row">
                <div class="col">
                  <label for="fromDate" class="sub">From Date</label>
                  <mat-form-field appearance="outline" class="w-100" >
                    <input matInput [matDatepicker]="startPicker" placeholder="DD-MMM-YYYY" name="validatetill" [(ngModel)]="fromDt" required>
                    <mat-datepicker-toggle matSuffix [for]="startPicker"></mat-datepicker-toggle>
                    <mat-datepicker #startPicker></mat-datepicker>
                  </mat-form-field>
                </div>
                <div class="col">
                  <label for="toDate" class="sub">To Date</label>
                  <mat-form-field appearance="outline" class="w-100" >
                    <input matInput [matDatepicker]="startPickers" placeholder="DD-MMM-YYYY" name="validatetill" [(ngModel)]="toDt" >
                    <mat-datepicker-toggle matSuffix [for]="startPickers"></mat-datepicker-toggle>
                    <mat-datepicker #startPickers></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <label for="file" class="sub">Qualification Proof</label>
              <ng-container *ngIf="errorFile !== ''">
                <div style="color: red; margin-top: 10px;">{{ errorFile }}</div>
              </ng-container>
              <div class="custom-file-input"  style="margin-bottom:10px;margin-top:10px" style="display: flex;">
                <input type="file" id="file" name="hcpEductaionUpload" accept="image/*" value="hcpEductaionUpload" (change)="onFileSelected($event)" style="display: none;" >
                <label for="file" class="uploadButton"><p></p>Upload</label>
                <div class="image-path">{{hcpEductaionUpload?.name ||"No File Choosen" }}</div> 
              </div>
             </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"  style="margin-right: 45%; width: 110px; height: 50px; border-radius: 8px; gap: 13px; background-color: #8F1402; margin-top: 20px; color: #FFFFFF;" >Save</button>
            </div>
           </div>
          </form>
         </div>
        </div>
       </div>
      </ng-template>

  <ng-template [ngIf]="displayName==='Licenseget'">
    <div class="main-content">
      <div class="container-fluid">
        <button mat-icon-button mat-dialog-close color="warn" style="margin-left:500px;" (click)="closeLicense()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="popcontainer">
          <form  (ngSubmit)="updatehcplicence(licenseId)">
            <div style="display: flex;">
              <h1 class="Details" style="margin-left: 150px;font-weight: 700;">License Details</h1>
              <button type="button" style="margin-left: 180px;" (click)="closeLicense()" class="btn-close" ></button>
            </div>
            <ng-container *ngIf="errorText !== ''">
                <div style="color: red; margin-top: 10px;">{{ errorText }}</div>
              </ng-container>
            <hr>
            <div class="form-container">
              <!-- License Authority -->
              <div class="form-group">
                <label for="License Authority"  class="sub">License Authority</label>
                <select [(ngModel)]="License_Authority" id="LicenseAuthority" name="License_Authority" class="form-control" style="max-width: 600px;" class="rounded-input" required>
                  <option value="DHA">DHA</option>
                  <option value="DOH">DOH</option>
                  <option value="MOH">MOH</option>
                  <option value="Other">Other</option>
                </select>
                <input type="text" [(ngModel)]="License_Authority_others" name='License_Authority_others'  *ngIf="License_Authority === 'Other'" placeholder="Enter other Authority" class="form-control" style="max-width: 600px;margin-top: 5px; "  class="rounded-input">
              </div>
              <!-- License Number -->
              <div class="form-group">
                <label for="License number # "  class="sub">License #</label>
                <input type="text" id="LicenseNumber" name="LicenseNumber" placeholder="Type here" size="60" class="rounded-input" [(ngModel)]="License_Number" required >
              </div>
              <div class="form-group">
                <label for="updspecialisation"  class="sub">Specialization</label>
                <input type="text" id="updspecialisation" name="LicenseSpecialisationUpdate" placeholder="Type here" size="60" class="rounded-input" [(ngModel)]="LicenseSpecialisationUpdate" required >
              </div>
              <!-- Validity Information -->
              <div class="form-group">
                <label for="Validity Information"  class="sub">Validity Information</label>
                <div class="row">
                  <!-- Issued date -->
                  
                  <div class="col">
                    <label for="issuedDate" class="col_1" style="font-size: small;">Issue date</label>
                    <mat-form-field appearance="outline" class="w-100" >
                      <input matInput [matDatepicker]="dtpicker" placeholder="DD-MMM-YYYY" name="Issued_Date" [(ngModel)]="Issued_Date" required>
                      <mat-datepicker-toggle matSuffix [for]="dtpicker"></mat-datepicker-toggle>
                      <mat-datepicker #dtpicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <!-- Validate till -->
                  <!-- <div class="col">
                    <label for="Validate_till" style="font-size: small;margin-top: 10px;">Valid till</label>
                    <input type="date" id="Validate_till" [(ngModel)]="Validate_till" [min]="getMinlicDate()" name="Validate_till" placeholder="DD-MMM-YYYY" class="rounded-input" [ngModelOptions]="{ standalone: true }" [required]="!Life_long_till"/>
                  </div> -->
                  <div class="col">
                    <label for="Validate_till" style="font-size: small;margin-top: 10px;">Valid till</label>
                    <mat-form-field appearance="outline" class="w-100" >
                      <input matInput [matDatepicker]="vtPicker" placeholder="DD-MMM-YYYY" name="Validate_till" [min]="getMinlicDate()" [(ngModel)]="Validate_till" [disabled]="lifelongvalidity === true" required>
                      <mat-datepicker-toggle matSuffix [for]="vtPicker"></mat-datepicker-toggle>
                      <mat-datepicker #vtPicker></mat-datepicker>
                    </mat-form-field>
                  </div>
                  <div class="col">
                    <input type="checkbox" id="Life_long_till" [(ngModel)]="Life_long_till" name="Life_long_till"  (change)="onvalidityupdchange()" required/>
                    <label for="Life_long_till" style="color: black; font-size: small; margin-left: 10px;">Life long Validity</label>
                  </div>
                </div>
              </div>
              <!-- File Upload -->
              <div class="form-group">
                <label for="file" class="sub">License Proof</label>
                <ng-container *ngIf="errorFile !== ''">
                    <div style="color: red; margin-top: 10px;">{{ errorFile }}</div>
                </ng-container>
              </div>
              <div class="custom-file-input"  style="margin-bottom:10px;margin-top:10px" style="display: flex;">
                <input type="file" id="file" name="licenseDoc" accept="image/*" value="licenseDoc" (change)="onFileSelected($event)" style="display: none;" >
                <label for="file" class="uploadButton"><p></p>Upload</label>
                <div class="image-path">{{licenseDoc?.name||licenseDocName ||"No File Choosen" }}</div> 
              </div>
              <div class="modal-footer">
                <button type="submit" style=" margin-right: 45%; width: 110px; height: 40px; border-radius: 8px; gap: 13px; background-color: #8F1402; margin-top: 20px;color: #FFFFFF;">Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    
  </ng-template>
   <ng-template [ngIf]="displayName==='Eductaionget'">
    <div class="main-content">
      <div class="container-fluid">
        <button mat-icon-button mat-dialog-close color="warn" style="margin-left:500px;" (click)="closeLicense()">
          <mat-icon>close</mat-icon>
        </button>
        <div class="popcontainer">
          <form  (ngSubmit)="educationUpd(EducationId)">
            <div style="display: flex;">
              <h1 class="Details" style="margin-left: 35%;font-weight: 700;">Education </h1>
              <button type="button" style="margin-left: 180px;" (click)="closeLicense()" class="btn-close" ></button>
            </div>
            <ng-container *ngIf="errorEducation !== ''">
                <div style="color: red; margin-top: 10px;">{{ errorEducation }}</div>
              </ng-container>
            <hr>
            <div class="form-container">
            <div class="modal-body" style="margin-right: 30px; margin-left: 30px;"> 
              <label for="Name of Institute" class="sub">Name of Institute</label><br>
              <input type="text" id="Name of Institute" name="Name of Institute" placeholder="Type here" size="60" style="margin-bottom: 20px;max-width: 600px;" class="rounded-input" [(ngModel)]="nameOfInstituteedu" required><br>

              <label for="Type of Degree" class="sub">Type of Degree</label><br>
              <input type="text" id="Type of Degree" name="Type of Degree" placeholder="Type here" size="60" style="margin-bottom: 20px;max-width: 600px;" class="rounded-input" [(ngModel)]="typeOfDegreeedu" required><br>

              <label for="field" class="sub">Field of Study</label><br>
              <input type="text" id="field" name="field" placeholder="Type here" size="60" style="margin-bottom: 20px;max-width: 600px;" class="rounded-input" [(ngModel)]="fieldOfStudyedu" required><br>

              <label for="country" class="sub">Country</label><br>
              <select name="Country" id="Country" class="form-control rounded" [(ngModel)]="countryedu" (ngModelChange)="onCountryChange($event)" style="width: 100%; background: #f9f9f9;" required>
                <option [value]="country" *ngFor="let country of CountryList; let i = index">{{ country }}</option>
              </select>          
              <div class="row">
                <!-- <div class="col">
                  <label for="fromDate" class="sub">From Date</label>
                  <input type="date" id="fromDate" name="fromDate" placeholder="From" style="max-width: 220px; margin-bottom: 20px;" class="rounded-input"[(ngModel)]="fromDtedu" required>
                </div> -->
                <div class="col">
                  <label for="fromDate" class="sub">From Date</label>
                  <mat-form-field appearance="outline" class="w-100" >
                    <input matInput [matDatepicker]="frmDt" placeholder="DD-MMM-YYYY" name="fromDtedu" [(ngModel)]="fromDtedu" required>
                    <mat-datepicker-toggle matSuffix [for]="frmDt"></mat-datepicker-toggle>
                    <mat-datepicker #frmDt></mat-datepicker>
                  </mat-form-field>
                </div>
               
                <div class="col">
                  <label for="toDate" class="sub">To Date</label>
                  <mat-form-field appearance="outline" class="w-100" >
                    <input matInput [matDatepicker]="toDt" placeholder="DD-MMM-YYYY" name="toDtedu" [(ngModel)]="toDtedu" required>
                    <mat-datepicker-toggle matSuffix [for]="toDt"></mat-datepicker-toggle>
                    <mat-datepicker #toDt></mat-datepicker>
                  </mat-form-field>
                </div>
              </div>
              <label for="file" class="sub">Qualification Proof</label>
              <div class="custom-file-input"  style="margin-bottom:10px;margin-top:10px" style="display: flex;">
                <input type="file" id="file" name="hcpEductaionUploadDoc" accept="image/*" value="hcpEductaionUploadDoc" (change)="onFileSelected($event)" style="display: none;" >
                <label for="file" class="uploadButton"><p></p>Upload</label>
                <div class="image-path">{{hcpEductaionUploadDoc?.name|| hcpEductaionUploadDocName ||"No File Choosen" }}</div> 
              </div>
            </div>
            <div class="modal-footer">
              <button type="submit" class="btn btn-primary"  style="margin-right: 45%; width: 110px; height: 50px; border-radius: 8px; gap: 13px; background-color: #8F1402; margin-top: 20px;color: #FFFFFF;" >Save</button>
            </div>
            </div>
          </form>
         </div>
        </div>
      </div>
    </ng-template>
       