<!-- <div class="main">
    <div class="sub-div">
        <ng-template [ngIf]="displayName==='profile'">>

    <div class="card-container_1">
        <div class="redcard" >
            <div class="top" >
                <p style=" font-size: 38px;margin-bottom: 30px;margin-left: 50px; ">Thank You !<span style="margin-left: 10px;font-size: 20px;">Registration Successful</span></p>
                <div>
                    <img style="width: 40px; margin-top: -120px;  margin-left: -440px;" src="assets/images/thumb.png">
                </div>
                
            </div>
            <div class="whitecardproperties" ></div>
                <div class="whitecard" >
                    <div class="a">
                        <div class="data" >
                            <p style="text-align: center;" >{{provideFullName}}</p>
                            <p style="text-align: center;" >{{provideMobileNumber}}</p>
                            <p style="word-break: break-all;">{{provideEmail}}</p>
                        </div>
                    </div>
                    <img style="width: 150px; height: 150px; left: 80px; top: 90px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); border-radius: 9999px; border: 5px #E9D0CC solid" [src]="Upload_Your_Photo || 'https://via.placeholder.com/240x240'" />
                    
                   
                    <div class="b">
                        <div  style="top: -120px; position: relative; background: white;  ">
                            <div class="c" >
                                <div class="d" >
                                    <p class="e" >{{Niv}}</p>
                                    <div class="f" style="margin-bottom: -10px;" >
                                        <p >DOB :</p>
                                        <p >{{provideDateOfBirth}}</p>
                                    </div>
                                    <div class="f" >
                                        <p >Gender :</p>
                                        <p >{{provideGender}}</p>
                                    </div>
                                </div>                      
                            </div>
                            
                        </div>
                    </div>
                    <img style="width: 150px; height: 150px; left: 360px; margin-top: 210px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);border-radius: 10px; border: 1px #E9D0CC solid" [src]="Hcp_qrcode || 'https://via.placeholder.com/240x240'" />

                    <img style="width: 110px; margin-top: 10px; margin-left: 110px;" src="assets/images/idlogo.svg">
                    <img style="width: 110px; margin-top: 380px; margin-left: 170px;" src="assets/images/idlogo.svg">
                   
                </div>
            </div>
            <div style="position: absolute; left: 602px; top: 670px;">
                <button style="margin-right: 10px; width: 150px; height: 40px;background-color: transparent;"class="rounded-input">Share</button>
                <button style="background-color: #8F1402; color: white; width: 150px; height: 40px;"class="rounded-input">Download</button>
            </div>
            <p class="description" >Novel Implementation of a Vision for an Integrated System of Healthcare</p>
            
        </div>                
       
    

            

        
        

   
</ng-template>
</div>
</div> -->
 
 <!-- <div class="main">
    <div class="sub-div">
      <ng-template [ngIf]="displayName==='profile'">
        <div class="card-container_1">
          <div class="redcard">
            <div class="top">
              <p style="font-size: 38px; margin-bottom: 30px; margin-left: 50px;">Thank You!<span style="margin-left: 10px; font-size: 20px;">Registration Successful</span></p>
              <div>
                <img style="width: 40px; margin-top: -120px; margin-left: -440px;" src="assets/images/thumb.png">
              </div>
            </div>
            <div class="whitecardproperties"></div>
            <div class="whitecard">
              <div class="a">
                <div class="data">
                  <p style="text-align: center;">{{studentFirstName}}</p>
                  <p style="text-align: center;">{{MobileNumber}}</p>
                  <p style="word-break: break-all;">{{provideEmail}}</p>
                </div>
              </div>
              <img style="width: 120px; height: 120px; left: 150px; top: 55px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); border-radius: 9999px; border: 10px #E9D0CC solid" [src]="profile" />
              <div class="b">
                <div style="top: -120px; position: relative; background: white;">
                  <div class="c">
                    <div class="d">
                      <p class="e">1000000254896</p>
                      <div class="f" style="margin-bottom: -10px;">
                        <p>DOB :</p>
                        <p>{{studentDOB}}</p>
                      </div>
                      <div class="f">
                        <p>Gender :</p>
                        <p>{{gender}}</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <img style="width: 90px; margin-top: 10px; margin-left: 300px;" src="assets/images/image 312.png">
            </div>
          </div>
          <div style="position: absolute; left: 602px; top: 670px;">
            <button style="margin-right: 10px; width: 150px; height: 40px; background-color: transparent;" class="rounded-input">Share</button>
            <button style="background-color: #8F1402; color: white; width: 150px; height: 40px;" class="rounded-input">Download</button>
          </div>
          <p class="description">Novel Implementation of a Vision for an Integrated System of Healthcare</p>
        </div>
      </ng-template>
    </div>
  </div>
   -->
   <div class="main">
    <div class="sub-div">
        <ng-template [ngIf]="displayName==='profile'">

    <div class="card-container_1">
        <div class="redcard" >
            <div class="top" >

                <p style=" font-size: 38px;margin-bottom: 30px; ">Thank You !</p>
                <span style="margin-left: -10px;font-size: 15px;">Registration Successful</span>
                <div>
                    <img style="width: 70px; margin-top: -100px;  margin-left: -400px;" src="assets/images/thumb.png">
                </div>
               
            </div>

            <div class="whitecardproperties" ></div>
                        <div id="downloadidcard">

                <!-- <div class="whitecard" >
                    <div class="a">
                        <div class="data" >
                            <h1>{{provideFullName}}</h1>
                            <p style="font-size: 14px;">{{provideMobileNumber}}</p>
                            <p style="font-size: 14px;"><ng-container *ngIf="provideEmail.length <= 24">
                                {{ provideEmail }}
                              </ng-container>
                              <ng-container *ngIf="provideEmail.length > 24">
                                {{ provideEmail.substring(0, 24) }}<br>{{ provideEmail.substring(24) }}
                              </ng-container></p>
                        
                        </div>
                    </div> -->
                    
                        <!-- <img style="width: 150px; height: 150px; left: 80px; top: 110px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25); border-radius: 9999px; border: 5px #E9D0CC solid" [src]="Upload_Your_Photo || 'https://via.placeholder.com/240x240'" />
                    <div class="b">
                        <div  style="top: -120px; position: relative; background: white;  ">
                            
                            <div class="c" >
                                <div class="data" >

                                   <h1>{{Niv}}</h1>
                                    <p style="font-size: 14px;">DOB : {{provideDateOfBirth}}</p>    
                                   <p style="font-size: 14px;">Gender : {{provideGender}}</p>
                                    
                               

                                </div>  

                            </div>


                        </div>


                    </div> -->
                    <!-- <img style="width: 150px; height: 150px; left: 360px; margin-top: 210px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);border-radius: 10px; border: 1px #E9D0CC solid" [src]="Hcp_qrcode || 'https://via.placeholder.com/240x240'" /> -->

                    <!-- <img style="width: 110px; margin-top: -345px; margin-left: 100px;" src="assets/images/idlogo.svg">
                    <img style="width: 110px; margin-top: 380px; margin-left: 170px;" src="assets/images/idlogo.svg"> -->

                <!-- </div> --> 
                
                <div class="whitecard" >
                    
                  <!-- <div class="a">
                      <div class="data" >
                      
                          <h1>{{FullName}}</h1>
                          <p>{{Registered_MobileNumber}}</p>
                          <p >
                              <ng-container *ngIf="Registered_Email.length <= 24">
                                {{ Registered_Email }}
                              </ng-container>
                              <ng-container *ngIf="Registered_Email.length > 24">
                                {{ Registered_Email.substring(0, 24) }}<br>{{ Registered_Email.substring(24) }}
                              </ng-container>
                            </p>
                      </div>
                  </div> -->
                  <div class="NIV">
                     <h1> <mat-label class="label-fixed-width">NIV<span>:</span></mat-label>
                      <span class="name"><strong>{{ Niv }}</strong></span>
                  </h1>
                  </div>
                  <div>
                      <img style="width: 150px; height: 150px; left: 120px; top: 120px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);" [src]="Upload_Your_Photo || 'https://via.placeholder.com/240x240'" />
                  </div>
                  <!-- <div class="b">
                      <div  style="top: -120px; position: relative; background: white;  ">
                          
                          <div class="c" >
                              <div class="data" >

                                 
                                      
                                 
                                  <h1>{{NIV}}</h1>
                                  <p>DOB : {{Date_of_Birth}}</p>
                                  <p>Gender : {{Gender}}</p>
                              </div>  

                          </div>


                      </div>


                  </div> -->
                  <!-- <img style="width: 150px; height: 150px; left: 360px; margin-top: 210px; position: absolute; box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);border-radius: 10px; border: 1px #E9D0CC solid" [src]="Hcp_qrcode || 'https://via.placeholder.com/240x240'" /> -->

                  <img style="width: 30%; margin-top: 30px; margin-left: -190px;" src="assets/images/idlogo.svg">
                  
                  <!-- <img style="width: 110px; margin-top: 380px; margin-left: 170px;" src="assets/images/idlogo.svg"> -->
                  
                  <div class="card">
                  <img style="width: 120px;margin-top: 2px; height: 122px; position: absolute; box-shadow: 0px 0px 4px rgba(9, 7, 7, 0.25);border-radius: 20px; border: 1px #E9D0CC solid" [src]="Hcp_qrcode || 'https://via.placeholder.com/240x240'" />
                  
                  <div class="details">
                      <div class="detail-item">
                          <mat-label class="label-fixed-width">Student Name<span>:</span></mat-label>
                          <span class="name"><strong>{{ provideFullName }}</strong></span>
                      </div>
                      <div class="detail-item">
                          <mat-label class="label-fixed-width">Mobile Number<span>:</span></mat-label>
                          <span class="Registered_MobileNumber"><strong>{{ provideMobileNumber }}</strong></span>
                      </div>
                      <div class="detail-item">
                          <mat-label class="label-fixed-width">Email<span>:</span></mat-label>
                              <span class="Registered_Email"><strong>{{ provideEmail }}</strong></span>
                        
                      </div>
                      <div class="detail-item">
                          <mat-label class="label-fixed-width">Gender<span>:</span></mat-label>
                          <span class="Gender"><strong>{{ provideGender }}</strong></span>
                      </div>
                      <div class="detail-item">
                          <mat-label class="label-fixed-width">DOB<span>:</span></mat-label>
                          <span class="Date_of_Birth"><strong>{{ provideDateOfBirth }}</strong></span>
                      </div>
                  </div>
                  
                  </div>
              </div>
            </div>
            </div>

            <div style="position: absolute; left: 635px; top: 670px;">
                <button style="margin-right: 10px; width: 150px; height: 40px;background-color: transparent;"class="rounded-input">Share</button>
                <button style="background-color: #8F1402; color: white; width: 150px; height: 40px;"class="rounded-input" (click)="downloadIdCard()">Download</button>

            </div>
            <p class="description" >Novel Implementation of a Vision for an Integrated System of Healthcare</p>
        </div> 
                 
</ng-template>
</div>
</div>